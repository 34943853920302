<template>
  <div id="page-member-profile-news-editor-modal">
    <memlist-modal
      size="xxl"
      ref="edit-form"
      :visible="show_modal"
      @close="onClose"
      hide-footer
    >
      <!-- News Name Input -->
      <div class="d-flex align-items-center mt-8">
        <memlist-text-input
          class="mt-8"
          id="name"
          name="name"
          :title="$t('NEWS.NAME')"
          layout="vertical"
          v-model="local_item.name"
          type="text"
        />

        <memlist-checkbox 
          :text="$t('NEWS.IS_PUBLISHED')"
          style="margin-top: 66px;"
          v-model="local_item.is_published"
        />
      </div>

      <memlist-text-input
        class="mt-8"
        id="excerpt"
        name="excerpt"
        :title="$t('NEWS.EXCERPT')"
        layout="vertical"
        v-model="local_item.excerpt"
        type="text"
      />

      <!-- Email Editor Container (max-height: 600px) -->
      <div style="max-height: 600px; overflow-y: auto;">
        <EmailEditor
          :appearance="appearance"
          :min-height="minHeight"
          :project-id="projectId"
          :locale="locale"
          :tools="tools"
          :options="options"
          ref="newsEditor"
          @load="editorLoaded"
          class="mt-8"
        />
      </div>

      <!-- Action Buttons -->
      <div class="modal-footer mt-4">
        <RightModalSaveAndCloseButtons
          :text="$t('COMMON.SAVE')"
          :spin="true"  
          @clicked="saveNewsArticle" 
          @close="onClose" 
          ref="saveButton" 
        />
      </div>
    </memlist-modal>
  </div>
</template>

<script>
import axios from 'axios';
import { EmailEditor } from 'vue-email-editor';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'MemberProfileNewsEditorModal',
  components: {
    EmailEditor
  },
  props: ['news_id'],
  mixins: [toasts],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'customer']),
    options() {
      return {
        user: {
          id: this.currentCompanyId
        },
        mergeTags: {},
        features: {
          userUploads: true
        }
      };
    }
  },
  data() {
    return {
      local_item: {},
      show_modal: false,
      // EmailEditor settings as per official documentation:
      minHeight: '1000px',
      locale: localStorage.getItem('language') || 'en',
      projectId: 14545, // Adjust your project id if needed.
      tools: {
        image: { enabled: true }
      },
      appearance: {
        theme: 'light',
        panels: {
          tools: { dock: 'right' }
        }
      }
    };
  },
  watch: {
    news_id: {
      handler(newVal) {
        if (newVal) {
          this.fetch_news_article(newVal);
        } else {
          this.local_item = {};
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Additional initialization if necessary.
  },
  methods: {
    onClose() {
      this.show_modal = false;
    },
    show() {
      this.show_modal = true;
    },
    async fetch_news_article(id) {
      try {
        const res = await axios.get(`/news/${id}`);
        if (res.status === 200) {
          this.local_item = res.data;
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('NEWS.UNABLE_GET'));
        }
      } catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('NEWS.UNABLE_GET'));
      }
    },
    /**
     * Called when the EmailEditor loads.
     * If an existing news item has a saved design, load it into the editor.
     */
    editorLoaded() {
      if (this.local_item.design) {
        let design;
        try {
          design = this.isJson(this.local_item.design)
            ? JSON.parse(this.local_item.design)
            : this.local_item.design;
          this.$refs.newsEditor.editor.loadDesign(design);
        } catch (e) {
          console.error('editorLoaded error', e);
        }
      }
    },
    /**
     * Save the news article by exporting both the design JSON and HTML
     * from the EmailEditor, then updating or creating the news record.
     */
    async saveNewsArticle() {
      this.$refs.newsEditor.editor.saveDesign((design) => {
        this.$refs.newsEditor.editor.exportHtml(async (data) => {
          // Save both design and html in the model
          this.local_item.design = JSON.stringify(design);
          this.local_item.html = data.html;

          if (this.local_item.id) {
            const res = await axios.put(`/news/${this.news_id}`, this.local_item);

            if (res.status === 200) {
              this.$emit('updated', this.local_item);
              
              this.onClose();
            } else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('NEWS.UNABLE_UPDATE'));
            }
          } else {
            this.local_item.customer_id = this.customer.id;
            this.local_item.company_id = this.currentCompanyId;

            const res = await axios.post(`/news`, this.local_item)

            if (res.status === 201) {
              this.$emit('created', this.local_item);

              this.onClose();
            } else {
              this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('NEWS.UNABLE_CREATE'));
            }
          }
        });
      });
    },
    /**
     * Utility method to check if a string is valid JSON.
     */
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped>
.unlayer-editor {
  height: 100vh;
}
</style>
