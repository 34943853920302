<template>
  <div>
    <!-- Top Controls -->
    <div class="px-4">

      <div class="d-flex justify-end">
        <memlist-icon-button
          icon="menu-icon flaticon2-plus"
          :text="$t('SPONSOR.CREATE_SPONSOR')"
          variant="primary"
          @click="create_sponsor_clicked"
        />

      </div>
      
      <!-- Filter Row -->
      <m-row>
        <m-col lg="3" sm="12">
          <b-form-group :label="$t('SPONSOR.IS_PUBLISHED')">
            <b-form-select
              v-model="filters.is_published"
              :options="published_options"
            ></b-form-select>
          </b-form-group>
        </m-col>

        <m-col lg="3" sm="12">
          <b-form-group
            id="input-group-created_from"
            :label="$t('COMMON.CREATED_FROM')"
            label-for="input-created_from"
          >
            <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
          </b-form-group>
        </m-col>
        <m-col lg="3" sm="12">
          <b-form-group
            id="input-group-created_to"
            :label="$t('COMMON.CREATED_TO')"
            label-for="input-created_to"
          >
            <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
          </b-form-group>
        </m-col>
      </m-row>

      <!-- Search Row -->
      <m-row>
        <m-col lg="6" sm="12">
          <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            style="max-width:621px"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
        </m-col>
      </m-row>
    </div>

    <!-- Pagination Controls -->
    <m-row class="mt-3">
      <m-col cols="4">
        <div class="bottom-alignment">
          <strong>{{ $t('COMMON.NUM_ROWS') }}:</strong> {{ total_rows }}
        </div>
      </m-col>
      <m-col cols="8">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">
          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 500, 1000]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            style="padding-left: 0px !important;"
            v-model="current_page"
            :total-rows="total_rows"
            :per-page="per_page"
            first-number
            last-number
          />
        </div>
      </m-col>
    </m-row>

    <!-- Sponsor Table -->
    <b-table
      style="width: 100%; table-layout: fixed;"
      ref="sponsor_table"
      class="table-striped"
      :filter="filters"
      :fields="headers"
      :items="ajax_pagination"
      :per-page="per_page"
      :current-page="current_page"
      head-variant="light"
    >
      <template #cell(is_published)="data">
        <memlist-badge :status="data.item.is_published ? 'published' : 'unpublished'">
          {{ data.item.is_published ? $t('COMMON.YES') : $t('COMMON.NO') }}
        </memlist-badge>
      </template>

      <template #cell(actions)="data">
        <memlist-table-button
          class="mr-3"
          @click="select_item_clicked(data.item.id)"
          svg="/assets/svg/Write.svg"
          :tooltip="$t('SPONSOR.TOOLTIP_SELECT_ITEM')"
        />
        <memlist-table-button
          class="mr-3"
          @click="delete_item_clicked(data.item.id)"
          svg="/assets/svg/Trash.svg"
          :tooltip="$t('SPONSOR.TOOLTIP_DELETE_ITEM')"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  name: 'SponsorTable',
  mixins: [toasts],
  emits: ['on_select_item', 'on_delete_item', 'create_clicked'],
  computed: {
    ...mapGetters(['currentCompanyId', 'settings', 'current_user']),
    published_options() {
      return [
        { text: this.$t('COMMON.ALL'), value: null },
        { text: this.$t('COMMON.YES'), value: true },
        { text: this.$t('COMMON.NO'), value: false }
      ];
    },
    headers() {
      return [
        {
          label: this.$t('SPONSOR.ID'),
          sortable: true,
          key: 'id',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('SPONSOR.NAME'),
          sortable: true,
          key: 'name',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('SPONSOR.IS_PUBLISHED'),
          sortable: true,
          key: 'is_published',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('SPONSOR.PUBLISHED_AT'),
          sortable: true,
          key: 'published_at',
          tdClass: 'td-overflow',
          thClass: 'td-overflow'
        },
        {
          label: this.$t('COMMON.ACTIONS'),
          key: 'actions',
          sortable: false,
        }
      ];
    }
  },
  mounted() {
    this.filters = {
      text: '',
      is_published: null,
      created_from: null,
      created_to: null
    };
  },
  data() {
    return {
      filters: {},
      total_rows: 0,
      current_page: 1,
      per_page: 100,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        // Optionally store filter preferences.
      }
    }
  },
  methods: {
    create_sponsor_clicked() {
      this.$emit('create_clicked');
    },
    select_item_clicked(id) {
      this.$emit('on_select_item', id);
    },
    delete_item_clicked(id) {
      this.$emit('on_delete_item', id);
    },
    get_filters_string() {
      let url_filters = '';
      if (this.filters.text) {
        url_filters += `&text=${encodeURIComponent(this.filters.text)}`;
      }
      if (this.filters.created_from) {
        url_filters += `&created_from=${this.filters.created_from}`;
      }
      if (this.filters.created_to) {
        url_filters += `&created_to=${this.filters.created_to}`;
      }
      if (this.filters.is_published !== null) {
        url_filters += `&is_published=${this.filters.is_published}`;
      }
      return url_filters;
    },
    get_pagination(callback) {
      axios
        .get(`/sponsor?page=${this.current_page}&limit=${this.per_page}${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination: invalid status', res.status);
            return;
          }
          if (callback) {
            callback(res.data || []);
          }
        })
        .catch(err => {
          console.error('pagination error', err);
        });
    },
    get_count() {
      axios
        .get(`/sponsor/count?${this.get_filters_string()}`)
        .then(res => {
          if (res.status !== 200) {
            console.error('pagination count: invalid status', res.status);
            return;
          }
          this.total_rows = res.data.count;
        })
        .catch(err => {
          console.error('pagination count error', err);
        });
    },
    ajax_pagination(ctx, callback) {
      this.get_count();
      this.get_pagination(callback);
      return null;
    },
    refresh() {
      this.$refs['sponsor_table'].refresh();
    }
  }
};
</script>

<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";

.status-badge {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 1pc;
  border: 1px solid;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.status-badge.published {
  background-color: white;
  color: #4caf50;
  border-color: #4caf50;
}

.status-badge.unpublished {
  background-color: white;
  color: #ff6a6a;
  border-color: #ff6a6a;
}
</style>
